import * as React from "react";

// scroll to top button
const ScrollToTop = () => {
  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4">
      <button
        className="bg-transparent hover:bg-indigo-400 text-indigo-400 hover:text-white py-2 px-4 border border-indigo-400 hover:border-transparent rounded"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="h-4 w-4"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
          />
        </svg>
      </button>
    </div>
  );
};

export default ScrollToTop;
