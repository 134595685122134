import * as React from "react";
import { graphql, StaticQuery } from "gatsby";

import HomepageSections from "./HomepageSections";
import GradientHeader from "../GradientHeader";

const About = () => {
  return (
    <StaticQuery
      query={query}
      render={({ file, site: { siteMetadata } }) => {
        const image_src = file.publicURL;
        return (
          <HomepageSections sectionId="about">
            <div className="grid md:grid-cols-2 gap-6 md:gap-10 justify-items-center content-center place-content-center">
              <div className="content">
                <GradientHeader href="#about">About Me</GradientHeader>
                {siteMetadata.author.about.map((paragraph:string, index:string) => (
                  <p className="pb-3 text-neutral-700 dark:text-neutral-300" key={index}>{paragraph}</p>
                ))}
              </div>
              <div className="md:mx-10 rounded-lg h-fit hover:rounded-none bg-indigo-400 hover:transparent hover:border-4 border-indigo-400 transition-all duration-200 ease-in hover:transition-all hover:duration-200 hover:ease-out">
                <div className="mix-blend-multiply hover:mix-blend-normal">
                  <img className="" src={image_src} alt="Silvi" />
                </div>
              </div>
            </div>
          </HomepageSections>
        );
      }}
    />
  );
};

const query = graphql`
  query ProfilePictureQuery {
    file(name: { eq: "ProfilePic" }) {
      publicURL
    }
    site {
      siteMetadata {
        author {
          about
        }
      }
    }
  }
`;

export default About;
