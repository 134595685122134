import { PageProps } from "gatsby"
import * as React from "react"

import Hero  from "../components/Home/Hero"
import About from "../components/Home/About"
import Experience from "../components/Home/Experience"
// import BlogPreview from "../components/Home/BlogPreview"
import Contact from "../components/Home/Contact"
import Seo from "../components/Seo"
import ScrollToTop from "../components/ScrollToTop"

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <Seo/>
      <Hero />
      <About />
      <Experience />
      {/* <BlogPreview /> */}
      <Contact />
      <ScrollToTop />
    </>
  )
}


export default IndexPage
