// Inspo: https://brittanychiang.com/#projects

import * as React from "react";

import { LinkedInLogo, GithubLogo, InstagramLogo } from "./SocialLogos";
import { InvertedButton } from "./Button";

interface SocialsProps {
  align?: string
}

const socials = [
  {
    text: "LinkedIn",
    href: "https://www.linkedin.com/in/silvianaho/",
    logo: <LinkedInLogo />,
    target: "_blank",
  },
  {
    text: "Github",
    href: "https://github.com/silvianaho/",
    logo: <GithubLogo />,
    target: "_blank",
  },
  {
    text: "Instagram",
    href: "https://www.instagram.com/v.studycorner/",
    logo: <InstagramLogo />,
    target: "_blank",
  }
]

const HeroSocials = ({ align="left" }: SocialsProps) => {
  const centerClasses: string = align === "center" ? " justify-center mx-auto" : "";
  const leftClasses: string = align === "left" ? " justify-start" : "";
  const rightClasses: string = align === "right" ? " justify-end" : "";
  const divClasses = "socials py-6 flex flex-row overflow-hidden md:max-w-md" + centerClasses + leftClasses + rightClasses;
  return (
    <div className={ divClasses }>
      { socials.map((social, index) => (
        <InvertedButton key={index} {...social} />
      ))}
    </div>
  );
};

export { HeroSocials };
