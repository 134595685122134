import * as React from "react";

import { H1 } from "./Header";

interface HeaderProps {
  className?: string;
  href?: string;
  children?: React.ReactNode;
}

const fancyHoverClassName =
  "w-fit text-white hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-indigo-400 hover:to-violet-400 transition-all duration-150 ease-in";

const getClassName = (customClassName: string, isLink: boolean) => {
  if (isLink) customClassName += " cursor-pointer"
  return [fancyHoverClassName, customClassName].join(" ");
};

const GradientHeader = ({
  className = "",
  href = "",
  children,
}: HeaderProps) => {
  return (
    <a href={href}>
      <H1 className={getClassName(className, href != "")}>{children}</H1>
    </a>
  );
};

export default GradientHeader;
