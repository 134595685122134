import * as React from "react";

interface HeaderProps {
  className?: string;
  children?: React.ReactNode;
}

const commonClassName = "font-bold text-gray-700 dark:text-white pb-4"

const getClassName = (sizeClassName:string, customClassName: string) => {
  return [sizeClassName, commonClassName, customClassName].join(" ")
}

const H1 = ({className="", children}: HeaderProps) => {
  return (
    <h1 className={getClassName("text-4xl", className)}>
      { children }
    </h1>
  );
};

const H2 = ({className="", children}: HeaderProps) => {
  return (
    <h2 className={getClassName("text-3xl", className)}>
      { children }
    </h2>
  );
};

const H3 = ({className="", children}: HeaderProps) => {
  return (
    <h3 className={getClassName("text-2xl", className)}>
      { children }
    </h3>
  );
};

const H4 = ({className="", children}: HeaderProps) => {
  return (
    <h4 className={getClassName("text-xl", className)}>
      { children }
    </h4>
  );
};

const H5 = ({className="", children}: HeaderProps) => {
  return (
    <h5 className={getClassName("text-lg", className)}>
      { children }
    </h5>
  );
};

const H6 = ({className="", children}: HeaderProps) => {
  return (
    <h6 className={getClassName("text-base", className)}>
      { children }
    </h6>
  );
};

export { H1, H2, H3, H4, H5, H6 };
