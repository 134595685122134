import * as React from "react"

const invertedButtonClasses: string =
  "bg-transparent hover:bg-indigo-500 text-indigo-400 hover:text-white py-2 px-4 md:px-4 mx-2 first:ml-0 last:mr-0 border-2 border-indigo-400 hover:border-white rounded-2xl inline-flex transition duration-150 ease-out md:ease-in";

// TODO:
// use object for to map colors to classes
const defaultFilledColor: string = "bg-indigo-500 hover:bg-indigo-600 text-white";

const filledButtonClasses: string =
  "py-2 px-4 mx-2 first:ml-0 last:mr-0 rounded-2xl inline-flex transition duration-150 ease-out md:ease-in";

// const filledButtonClasses: string =
//   "bg-gray-400 hover:bg-gray-100 text-gray-700 py-1 px-4 md:px-4 mx-2 first:ml-0 last:mr-0 rounded-2xl inline-flex transition duration-150 ease-out md:ease-in";


const buttonSpanClasses: string = "hidden md:inline"

interface buttonProps {
  text: string;
  href?: string;
  logo?: React.ReactNode;
  target?: string;
  className?: string;
}

const getClassName = (buttonClassName:string, customClassName: string) => {
  return [buttonClassName, customClassName].join(" ")
}


const InvertedButton = ({text, href, logo, target, className=""}: buttonProps) => {
  return (
    <a
      href={href}
      target={target}
      aria-label={text}
      rel="nofollow noopener noreferrer"
      className={getClassName(invertedButtonClasses, className)}
    >
      { logo ? logo : null }
      <span className={logo ? buttonSpanClasses : ""}>{text}</span>
    </a>
  );
}

const FilledButton = ({text, href, logo, target, className=""}: buttonProps) => {
  return (
    <a
      href={href}
      target={target}
      aria-label={text}
      rel="nofollow noopener noreferrer"
      className={getClassName(filledButtonClasses, className)}
    >
      { logo ? logo : null }
      <span className={logo ? buttonSpanClasses : ""}>{ text }</span>
    </a>
  );
}

export { InvertedButton, FilledButton };
