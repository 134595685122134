import * as React from "react";
import { graphql, StaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import HomepageSections from "./HomepageSections";
import GradientHeader from "../GradientHeader";
import { H3 } from "../Header";

const transitionClasses =
  "transition-all duration-200 ease-in hover:transition-all hover:duration-200 hover:ease-out";

interface experienceItemProps {
  node: any;
  img_src: string;
}

const ExperienceItem = ({ node, img_src }: experienceItemProps) => {
  return (
    <div className="experience-item grid md:grid-cols-6 gap-10 ">
      <div className="md:col-span-2 experience-item-picture">
        <div
          className={[
            "rounded-lg hover:rounded-none bg-indigo-400 hover:bg-transparent hover:outline hover:outline-4 hover:outline-indigo-400/50",
            transitionClasses,
          ].join(" ")}
        >
          <div
            className={[
              "mix-blend-multiply hover:mix-blend-normal",
              transitionClasses,
            ].join(" ")}
          >
            <img
              src={img_src}
              className="rounded-lg hover:rounded-none"
              alt={node.frontmatter.featuredImageAlt}
            />
          </div>
        </div>
      </div>
      <div className="md:col-span-4 experience-text">
        <div className="experience-item-header">
          <H3 className="pb-0">
            {node.frontmatter.position}
            <span className="text-indigo-400">
              {" "}
              @ {node.frontmatter.companyName}
            </span>
          </H3>
          <p className="text-neutral-600 dark:text-neutral-400 italic">
            {node.frontmatter.start} - {node.frontmatter.end} (
            {node.frontmatter.duration})
          </p>
        </div>
        <div className="experience-item-body pt-2">
          <MDXRenderer>{node.body}</MDXRenderer>
        </div>
      </div>
    </div>
  );
};

const ExperienceList = () => (
  <StaticQuery
    query={query}
    render={({ file, allMdx }) => {
      return (
        <>
          {allMdx.nodes.map((node: any) => {
            let img_src = node.frontmatter.featuredImage
              ? node.frontmatter.featuredImage.publicURL
              : file.publicURL;
            return (
              <ExperienceItem key={node.id} node={node} img_src={img_src} />
            );
          })}
        </>
      );
    }}
  />
);

const Experience = () => {
  return (
    <HomepageSections sectionId="experience">
      <GradientHeader href="#experience">Experience</GradientHeader>
      <div className="experience-container grid auto-cols-auto gap-10">
        <ExperienceList />
      </div>
    </HomepageSections>
  );
};

export const query = graphql`
  query ExperiencesQuery {
    file(name: { eq: "DefaultBlogImg" }) {
      publicURL
    }
    allMdx(
      filter: { frontmatter: { variant: { eq: "experience" } } }
      sort: { fields: frontmatter___end, order: DESC }
    ) {
      nodes {
        id
        body
        frontmatter {
          companyName
          duration
          end(formatString: "MMMM YYYY")
          start(formatString: "MMMM YYYY")
          title
          position
          featuredImageAlt
          featuredImage {
            publicURL
          }
        }
      }
    }
  }
`;

export default Experience;
