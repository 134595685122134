import * as React from "react";

import HomepageSections from "./HomepageSections";
import { HeroSocials } from "../Socials";

const Hero = () => {
  return (
    <>
      <HomepageSections sectionId="top">
        <h1 className="text-indigo-400 dark:text-indigo-400">
          Hello! My name is
        </h1>
        <h1 className="text-6xl md:text-8xl font-semibold text-gray-700 dark:text-white hover:text-stone-900 dark:hover:text-white">
          Silviana.
        </h1>
        <div className="summary w-full md:max-w-lg">
          <h2 className="text-gray-700 dark:text-gray-400">
            I am an aspiring software engineer with experience in full-stack web
            development and data analytics.
          </h2>
        </div>
        <HeroSocials align="left" />
      </HomepageSections>
    </>
  );
};

export default Hero;
