import * as React from "react";

const logoClasses: string = "md:mr-2";

const LinkedInLogo = () => (
  <svg
    width="20"
    height="20"
    className={logoClasses}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88235 7.64706H2.94118V17.0588H5.88235V7.64706ZM6.14706 4.41177C6.14861 4.18929 6.10632 3.96869 6.02261 3.76255C5.93891 3.55642 5.81542 3.36879 5.65919 3.21039C5.50297 3.05198 5.31708 2.92589 5.11213 2.83933C4.90718 2.75277 4.68718 2.70742 4.46471 2.70588H4.41177C3.95934 2.70588 3.52544 2.88561 3.20552 3.20552C2.88561 3.52544 2.70588 3.95934 2.70588 4.41177C2.70588 4.86419 2.88561 5.29809 3.20552 5.61801C3.52544 5.93792 3.95934 6.11765 4.41177 6.11765C4.63426 6.12312 4.85565 6.0847 5.06328 6.00458C5.27092 5.92447 5.46074 5.80422 5.62189 5.65072C5.78304 5.49722 5.91237 5.31346 6.00248 5.10996C6.09259 4.90646 6.14172 4.6872 6.14706 4.46471V4.41177ZM17.0588 11.3412C17.0588 8.51177 15.2588 7.41177 13.4706 7.41177C12.8851 7.38245 12.3021 7.50715 11.7799 7.77345C11.2576 8.03974 10.8143 8.43832 10.4941 8.92941H10.4118V7.64706H7.64706V17.0588H10.5882V12.0529C10.5457 11.5403 10.7072 11.0315 11.0376 10.6372C11.3681 10.2429 11.8407 9.99488 12.3529 9.94706H12.4647C13.4 9.94706 14.0941 10.5353 14.0941 12.0176V17.0588H17.0353L17.0588 11.3412Z"
      fill="currentColor"
    />
  </svg>
);

const GithubLogo = () => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={logoClasses}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.475 0 0 4.58819 0 10.2529C0 14.7899 2.8625 18.6219 6.8375 19.9804C7.3375 20.0701 7.525 19.7625 7.525 19.4934C7.525 19.2499 7.5125 18.4425 7.5125 17.5838C5 18.058 4.35 16.9558 4.15 16.3791C4.0375 16.0843 3.55 15.1743 3.125 14.9308C2.775 14.7386 2.275 14.2644 3.1125 14.2516C3.9 14.2388 4.4625 14.9949 4.65 15.3025C5.55 16.8533 6.9875 16.4175 7.5625 16.1484C7.65 15.4819 7.9125 15.0334 8.2 14.777C5.975 14.5207 3.65 13.6364 3.65 9.71466C3.65 8.59965 4.0375 7.67689 4.675 6.95918C4.575 6.70286 4.225 5.65193 4.775 4.24215C4.775 4.24215 5.6125 3.97301 7.525 5.29308C8.325 5.06239 9.175 4.94704 10.025 4.94704C10.875 4.94704 11.725 5.06239 12.525 5.29308C14.4375 3.9602 15.275 4.24215 15.275 4.24215C15.825 5.65193 15.475 6.70286 15.375 6.95918C16.0125 7.67689 16.4 8.58684 16.4 9.71466C16.4 13.6492 14.0625 14.5207 11.8375 14.777C12.2 15.0975 12.5125 15.7126 12.5125 16.6738C12.5125 18.0452 12.5 19.1474 12.5 19.4934C12.5 19.7625 12.6875 20.0829 13.1875 19.9804C15.1727 19.2933 16.8977 17.9852 18.1198 16.2402C19.3419 14.4952 19.9995 12.4012 20 10.2529C20 4.58819 15.525 0 10 0Z"
      fill="currentColor"
    />
  </svg>
);

const InstagramLogo = () => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={logoClasses}
  >
    <path
      d="M10 1.8025C12.67 1.8025 12.9867 1.8125 14.0417 1.86083C16.7517 1.98417 18.0175 3.27 18.1408 5.96C18.1892 7.01417 18.1983 7.33083 18.1983 10.0008C18.1983 12.6717 18.1883 12.9875 18.1408 14.0417C18.0167 16.7292 16.7542 18.0175 14.0417 18.1408C12.9867 18.1892 12.6717 18.1992 10 18.1992C7.33 18.1992 7.01333 18.1892 5.95917 18.1408C3.2425 18.0167 1.98333 16.725 1.86 14.0408C1.81167 12.9867 1.80167 12.6708 1.80167 10C1.80167 7.33 1.8125 7.01417 1.86 5.95917C1.98417 3.27 3.24667 1.98333 5.95917 1.86C7.01417 1.8125 7.33 1.8025 10 1.8025V1.8025ZM10 0C7.28417 0 6.94417 0.0116667 5.8775 0.06C2.24583 0.226667 0.2275 2.24167 0.0608333 5.87667C0.0116667 6.94417 0 7.28417 0 10C0 12.7158 0.0116667 13.0567 0.06 14.1233C0.226667 17.755 2.24167 19.7733 5.87667 19.94C6.94417 19.9883 7.28417 20 10 20C12.7158 20 13.0567 19.9883 14.1233 19.94C17.7517 19.7733 19.775 17.7583 19.9392 14.1233C19.9883 13.0567 20 12.7158 20 10C20 7.28417 19.9883 6.94417 19.94 5.8775C19.7767 2.24917 17.7592 0.2275 14.1242 0.0608333C13.0567 0.0116667 12.7158 0 10 0V0ZM10 4.865C7.16417 4.865 4.865 7.16417 4.865 10C4.865 12.8358 7.16417 15.1358 10 15.1358C12.8358 15.1358 15.135 12.8367 15.135 10C15.135 7.16417 12.8358 4.865 10 4.865ZM10 13.3333C8.15917 13.3333 6.66667 11.8417 6.66667 10C6.66667 8.15917 8.15917 6.66667 10 6.66667C11.8408 6.66667 13.3333 8.15917 13.3333 10C13.3333 11.8417 11.8408 13.3333 10 13.3333ZM15.3383 3.4625C14.675 3.4625 14.1375 4 14.1375 4.6625C14.1375 5.325 14.675 5.8625 15.3383 5.8625C16.0008 5.8625 16.5375 5.325 16.5375 4.6625C16.5375 4 16.0008 3.4625 15.3383 3.4625Z"
      fill="currentColor"
    />
  </svg>
);

const MailIcon = () => (
  <span className={logoClasses}>💌 </span>
)

export { LinkedInLogo, GithubLogo, InstagramLogo, MailIcon };
