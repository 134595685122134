import * as React from "react";

interface LayoutProps {
  sectionId: string;
  children: React.ReactNode
}


const HomepageSections = ({ sectionId, children }: LayoutProps) => {
  return (
    <section
      id={sectionId}
      className="container min-h-screen w-full flex flex-col align-start justify-center"
    >
      { children }
    </section>
  );
};

export default HomepageSections;
