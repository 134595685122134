import * as React from "react";
import HomepageSections from "./HomepageSections";

import GradientHeader from "../GradientHeader";
import { InvertedButton } from "../Button";
import { MailIcon } from "../SocialLogos";
import { HeroSocials } from "../Socials";

const Contact = () => {
  return (
    <HomepageSections sectionId="contact">
      <div className="text-center mx-auto">
        <GradientHeader href="#contact" className="mx-auto">Get In Touch</GradientHeader>
        <div className="py-4 w-full md:max-w-lg mx-auto">
          <p className="text-gray-700 dark:text-gray-400">
            My inbox is always open. Whether you have a question or just want to
            say hi, please don't hesitate to contact me. I'll try my best to get
            back to you!
          </p>
          <div className="py-4">
            <HeroSocials align="center" />
            <InvertedButton
              text="silvi@silvi.io"
              logo={<MailIcon />}
              href="mailto:silvi@silvi.io"
            />
          </div>
        </div>
      </div>
    </HomepageSections>
  );
};

export default Contact;
